import { Lafz } from "./pages/Lafz";
import { Home }  from "./pages/Home";
import { Menu } from "./pages/Menu";
import { Login } from "./pages/Login";
import { ErrorPage }  from "./pages/ErrorPage";

import './styles/style.css';
import './styles/responsive.css';
import './styles/css/all.css';
import './styles/fonts.css';


import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
  } from "react-router-dom";
  
const router = createBrowserRouter([
{
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
},
{
    path: "lafz",
    element: <Lafz />,
},
{
    path: "menu",
    element: <Menu />,
},
{
    path: "login",
    element: <Login />,
},

]);

export default function App(){
    return (
        <>
            <RouterProvider router={router} />
        </>
    )
}