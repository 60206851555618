export function Comment() {
    return(
        <>
            <div className="comment-input-bar">
                <div className="comment-share">
                    <i class="fa-solid fa-share"></i>
                </div>
                <div className="comment-input-div">
                    <input className="comment-input" type="text" placeholder="کامنت ..." />
                </div>
            </div>
            <div className="comment-bar">
                <div className="comments">
                    <div className="comment-user">
                        <i class="fa-regular fa-user"></i>
                        <p>امیر لفظ باز</p>
                    </div>
                    <div className="comment">
                        <p>خیلی باحال بود 😂😂😂</p>
                    </div>
                </div>
            </div>
        </>
    )
}