import {
    Link,
  } from "react-router-dom";

export function LafzCard(props) {
    return(
        <div className="lafz-card">
            <Link className="link" to={`./Lafz`}>
            <div className="lafz">
                <h1>{props.lafz}</h1>
            </div>
            <div className="lafz-description">
                <p>{props.description}</p>
            </div>
            </Link>
            <div className="lafz-hashtag">
            {
                props.hashtags.map( tag => (
                <div>
                    <div>
                        <a href="#">#{tag}</a>
                    </div>
                </div>
                ))
            }
            </div>
            <div className="lafz-icon">
                <div>
                    <p>{props.like} <i class="fa-regular fa-heart"></i></p>
                </div>
                <div>
                    <i class="fa-solid fa-volume-high"></i>
                </div>
                <Link className="link" to={`./Lafz`}>
                <div>
                    <p>{props.comment} <i class="fa-regular fa-comment"></i></p>
                </div>
                </Link>
                <div>
                    <i class="fa-regular fa-share-from-square"></i>
                </div>
            </div>
        </div>
    )
}