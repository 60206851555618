import {
    Link,
  } from "react-router-dom";

export function Login() {
    return(
        <body className="login-page">
            <header className='menu-header'>
                <Link className="back-link" to={`/`}>
                    <i class="fa-regular fa-circle-xmark"></i>
                </Link>
            </header>
            <div className="login">
                <div className="login-text">
                    <h1>ورود / ثبت نام</h1>
                </div>
                <div className="login-input">
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                </div>
                <div className="login-btn">
                    <button>ورود</button>
                </div>
                <div className="login-google">

                </div>
            </div>
        </body>
    )
}