import {
    Link,
  } from "react-router-dom";

export function MainHeader() {
    return(
        <header className="header">
            <Link className="link" to={`/Menu`}>
            <div className="menu">
                <i class="fa-solid fa-burger"></i>
            </div>
            </Link>
            <Link className="link" to={`/`}>
                <div className="back">
                    <i class="fa-regular fa-circle-left"></i>
                </div>
            </Link>
        </header>
    )
}