import { MainHeader } from "../MainHeader";
import { LafzIPA } from "../LafzIPA";
import { Comment } from "../Comment";

import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

export function Lafz(props){
    return(
        <main className="main">
            <div className="main-div">
        <MainHeader />
        <LafzIPA
            lafz = "لس موس"
            ipa = " Lase Moos "
            hashtags = {["مازندران","لفظ","فان"]}
            description = "به شخصی گفته میشه که خیلی شل ، تنبل ، یا به عبارتی همون گشاده. مثلا میگن امیر چقدر لسه موسییی ..."
            like = "1"
            comment = "1"
        />
        <Comment />
            </div>
        </main>
    )
}