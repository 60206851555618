import { Header } from "../Header"
import { LafzCard } from "../LafzCard"

import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/css/all.css'

import {Link} from "react-router-dom";

import {useState, useEffect} from 'react'

export function Home(){
    const [expressions, setExpression] = useState([])

    useEffect( () => {
        getExpressions()
    }, [])

    async function getExpressions(){
        const response = await fetch('https://lafzhub.com/api/expressions')
        const expressions = (await response.json()).data
        setExpression(expressions)
        console.log(expressions)
    }

    return (
        <main className="main">
            <div className="main-div">
            <Header />

            {
            expressions.map( expression => <LafzCard
                lafz = {expression.expression}
                ipa = {expression.expression}
                hashtags = {["لفظ","فان"]}
                description = {expression.description}
                like = "1"
                comment = "1"
                key={ expression.id}
            />)
            }
            
            <div className="soon">
                <div>
                    <h1>...Comming Soon</h1>
                </div>
                <div>
                    <h2>Testing Step</h2>
                </div>
            </div>
            </div>
        </main>
    )
}
